.imgCoursel{
  width: auto;
  height: 70vh;
  margin: 0 auto;
}

.imagesCarousel{
  width: 45%;
  height: 65vh;
}

@media (max-width: 1000px){
  .imagesCarousel{
    width: 100%;
    height: auto;
  }
}


